<template>
  <div id="homeiz">
    <div class="wrapper peep-page">
      <div id="google_translate_element"></div>
      <!-- Sidebar  -->
      <SideBarStyle1 :items="verticalMenu" :logo="logo" @toggle="sidebarMini" />

      <SideBarSearch :class="showSearch ? 'd-flex' : 'd-none'" @hide="showSearchAction(false)"/>

      <SideBarLanguages :class="showLanguages ? 'd-flex' : 'd-none'" @hide="showLanguagesAction(false)"/>

      <SideBarNotifications :class="showNotifications ? 'd-flex' : 'd-none'" @hide="showNotificationsAction(false)" />

      <file-uploader
        image="vids-uploader.svg"
        title="Drag videos here"
        subTitle="Supported formats MP4, WebM or OGG"
        description="Up to 90 seconds"
        accept="video/*"
        :limit="1"
        :size-limit="vidsFileSize"
      />

      <!-- TOP Nav Bar -->
      <!-- <NavBarStyle1 title="Dashboard" :homeURL="{ name: 'social.feed' }" @toggle="sidebarMini" :logo="logo" /> -->

      <!-- Right Nav Bar -->
      <div from="rightSidebar"></div>
      <!-- Right Sidebar Panel End-->

      <!-- TOP Nav Bar END -->
      <div id="content-page" class="content-page">
        <div class="container">
          <router-view/>
        </div>
      </div>
    </div>

    <ScrollToTop/>

    <b-modal
      id="feedback-modal"
      title="Give Us Feedback About a MeTwitt Feature"
      hide-footer content-class="rounded"
      no-fade
      size="lg"
    >
      <div>
        <give-feedback-modal />
      </div>
    </b-modal>
    <b-modal
      id="close-account-modal"
      title="Close account"
      hide-footer content-class="rounded"
      no-fade
      size="lg"
    >
      <div>
        <close-account-modal />
      </div>
    </b-modal>

    <!-- <FooterStyle1>
      <template v-slot:left>
        <FooterMenu :items="footerMenu"/>
      </template>
      <template v-slot:right>
        Copyright &#169; 2016-{{ currentYear }} <a href="/">HOMEiZ</a> A Media Group LLC. All rights reserved.
      </template>
    </FooterStyle1> -->
  </div>
</template>
<script>
import SideBarStyle1 from '../components/socialvue/sidebars/SideBarStyle1'
import SideBarSearch from '../components/socialvue/sidebars/SideBarSearch'
import SideBarLanguages from '../components/socialvue/sidebars/SideBarLanguages'
import SideBarNotifications from '../components/socialvue/sidebars/SideBarNotifications'
import CloseAccountModal from '@/components/homeiz/modals/CloseAccountModal.vue'
import GiveFeedbackModal from '@/components/homeiz/modals/GiveFeedbackModal.vue'
// import NavBarStyle1 from '../components/socialvue/navbars/NavBarStyle1'
import SideBarItems from '../FackApi/json/SideBar'
import FooterMenuItems from '@/FackApi/json/FooterMenu'
import profile from '../assets/images/user/user-1.jpeg'
import logo from '@/assets/images/metwitt-logo-small.svg'
import ScrollToTop from '@/components/homeiz/common/ScrollToTop'
// import FooterStyle1 from '../components/socialvue/footer/FooterStyle1'
import { socialvue } from '../config/pluginInit'
import { Users } from '../FackApi/api/chat'
import { mapActions, mapGetters } from 'vuex'
// import FooterMenu from '@/components/homeiz/menus/FooterMenu'
import * as authGetters from '@/store/modules/auth/types/getters'
import store from '@/store'
import authService from '@/services/auth/authServiceMock'
import * as authActions from '@/store/modules/auth/types/actions'
import FileUploader from '@/components/homeiz/common/form/FileUploaderVids'
import { MAX_VIDS_FILE_SIZE } from '@/config/file.js'

export default {
  name: 'Layout1',
  components: {
    // FooterMenu,
    SideBarStyle1,
    CloseAccountModal,
    GiveFeedbackModal,
    // NavBarStyle1,
    ScrollToTop,
    // FooterStyle1,
    SideBarSearch,
    SideBarLanguages,
    SideBarNotifications,
    FileUploader
  },
  mounted () {
    this.logo = logo
  },
  provide () {
    return {
      authUser: this.authUser
    }
  },
  async beforeRouteEnter (to, from, next) {
    if (!store.state.auth.authUser && authService.hasToken()) {
      await store.dispatch('auth/' + authActions.FETCH_LOGGED_USER)
      next()
    } else {
      next()
    }
  },
  computed: {
    ...mapGetters('auth', {
      authUser: authGetters.GET_AUTH_USER
    }),
    ...mapGetters('settings', {
      showSearch: 'searchState',
      showLanguages: 'languagesState',
      showNotifications: 'notificationsState'
    }),
    currentRouteName () {
      return this.$route.name
    },
    currentYear () {
      return new Date().getFullYear()
    }
  },
  watch: {
  },
  // sidebarTicket
  data () {
    return {
      animated: { enter: 'zoomIn', exit: 'zoomOut' },
      animateClass: [
        { value: { enter: 'zoomIn', exit: 'zoomOut' }, text: 'Zoom' },
        { value: { enter: 'fadeInUp', exit: 'fadeOutDown' }, text: 'Fade' },
        { value: { enter: 'slideInLeft', exit: 'slideOutRight' }, text: 'Slide' },
        { value: { enter: 'rotateInDownLeft', exit: 'rotateOutDownLeft' }, text: 'Roll' }
      ],
      verticalMenu: SideBarItems,
      footerMenu: FooterMenuItems,
      userProfile: profile,
      logo: logo,
      usersList: Users,
      message: [
        { image: require('../assets/images/user/user-01.jpg'), name: 'Nik Emma Watson', date: '13 jan' },
        { image: require('../assets/images/user/user-02.jpg'), name: 'Greta Life', date: '14 Jun' },
        { image: require('../assets/images/user/user-03.jpg'), name: 'Barb Ackue', date: '16 Aug' },
        { image: require('../assets/images/user/user-04.jpg'), name: 'Anna Sthesia', date: '21 Sept' },
        { image: require('../assets/images/user/user-05.jpg'), name: 'Bob Frapples', date: '29 Sept' }
      ],
      notification: [
        { image: require('../assets/images/user/user-01.jpg'), name: 'Nik Emma Watson', date: '23 hour ago', description: 'Enjoy smart access to videos, games' },
        { image: require('../assets/images/user/user-02.jpg'), name: 'Greta Life', date: '14 hour ago', description: 'Google Chromecast: Enjoy a world of entertainment' },
        { image: require('../assets/images/user/user-03.jpg'), name: 'Barb Ackue', date: '16 hour ago', description: 'Dell Inspiron Laptop: Get speed and performance from' },
        { image: require('../assets/images/user/user-04.jpg'), name: 'Anna Sthesia', date: '21 hour ago', description: 'Deliver your favorite playlist anywhere in your home ' },
        { image: require('../assets/images/user/user-05.jpg'), name: 'Bob Frapples', date: '11 hour ago', description: 'MacBook Air features up to 8GB of memory, a fifth-generation' }
      ],
      vidsFileSize: MAX_VIDS_FILE_SIZE
    }
  },
  methods: {
    ...mapActions({
      modeChange: 'settings/layoutModeAction',
      showSearchAction: 'settings/showSearchAction',
      showLanguagesAction: 'settings/showLanguagesAction',
      showNotificationsAction: 'settings/showNotificationsAction'
    }),
    sidebarMini () {
      socialvue.triggerSet()
    },
    logout () {
      localStorage.removeItem('user')
      localStorage.removeItem('access_token')
      this.$router.push({ name: 'auth1.sign-in1' })
    },
    langChange (lang) {
      this.langChangeState(lang)
      this.$i18n.locale = lang.value
      document.getElementsByClassName('iq-show')[0].classList.remove('iq-show')
    },
    ...mapActions({
      langChangeState: 'Setting/setLangAction'
    })
  }
}
</script>
<style>
  @import url("../assets/css/custom.css");
  @import url("../assets/css/PriceSlider.css");
</style>
